/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/algoliasearch@4.24.0/dist/algoliasearch.umd.min.js
 * - /npm/@algolia/autocomplete-js@1.18.0/dist/umd/index.production.min.js
 * - /npm/@algolia/autocomplete-plugin-recent-searches@1.18.0/dist/umd/index.production.min.js
 * - /npm/@algolia/autocomplete-plugin-query-suggestions@1.18.0/dist/umd/index.production.min.js
 * - /npm/instantsearch.js@4.77.3/dist/instantsearch.production.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
